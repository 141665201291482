import { observable, action, computed, autorun, toJS } from 'mobx'
import { fromPromise } from 'mobx-utils'

import { api } from 'utils/fetcher'
import { withStatusFor } from 'utils/storeHelpers'

const byLastThenFirst = (a, b) => {
  if (a.last_name > b.last_name) {
    return 1;
  } else if (a.last_name < b.last_name) {
    return -1;
  }

  if (a.first_name < b.first_name) {
    return -1;
  } else if (a.first_name > b.first_name) {
    return 1
  } else {
    return 0;
  }
}

class ContactsStore {

  @observable _contacts = fromPromise(api.get().from.contacts.all())
  @observable _storedContacts = []
  @computed get link() { return this._contacts.value?.contacts }
  @computed get contacts() {
    const contacts = this.isLoading ? toJS(this._storedContacts) : toJS(this._contacts.value?.contacts)
    return contacts?.sort(byLastThenFirst) || []
  }

  @action getContacts = () => {
    this._storedContacts = this.contacts
    this._contacts = fromPromise(api.get().from.contacts.all())
  }

  refreshStore = () => this.getContacts()

}
export const contactsStore = new ContactsStore()
export default withStatusFor(contactsStore, { withMainField: '_contacts',onPath:'\/main\/contacts'})
