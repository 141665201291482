import React, { Component, createRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { observable, action, computed } from 'mobx'
import { observer, inject } from 'mobx-react'
import { isBefore, format, parseISO, subMinutes } from 'date-fns'
import tinycolor from 'tinycolor2'

import jsbridge from 'utils/jsbridge'
import config from 'utils/config'
import newrowImg from 'assets/images/courseDefaultAvatar.jpg'

import { ContentDetails, Thumb } from 'components/Content'
import { theme, Img, Text, Button as ButtonUI, Modal, Spinner, ButtonsRow, ScrollableText } from 'UI'

const NewrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
`

const StyledButtonsRow = styled(ButtonsRow)`
  margin-left: ${theme.interval(4)};
`

const Button = styled(ButtonUI)`
  margin-bottom: ${theme.interval(1.5)};
`


const ModalTitle = styled(Text.H2)`
  margin-bottom: ${theme.interval()};
`

const ModalContent = styled(Text.H2)`
  margin-bottom: ${theme.interval()};
`

const MessageModal = styled.div`
  min-width: 55vw;
  height: 100%;
  padding: ${theme.interval(2)};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ClassModal = styled(Modal)`
  background: ${p => p.color ? `linear-gradient(to right bottom, ${tinycolor(p.color).lighten(40).toHexString()}, ${tinycolor(p.color).darken(10).toHexString()})` : ''};
  max-height: 100vh;
`

const Viewer = styled.div`
  background: transparent;
  position: relative;
  display: flex;
  justify-content: flex-start;
`

const Buttons = styled.div`
  bottom: -2vh;
  padding: ${theme.padding};
  background: transparent;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:  stretch;
  box-sizing: border-box;
  border-radius: ${theme.borderRadius}; 
`

const ButtonsModal = styled(ButtonsRow)`
    margin-top: ${theme.interval()};
`

const notSelected = css`
background: rgba(0,0,0,0.15);
`

const styles = {
  notSelected: notSelected
}

const StyledButton = styled(Button).attrs(() => ({
  styles: styles
}))`
  ;margin:  ${theme.interval(1.5)}  0 ;
  &:last-child, &:first-child {
    margin: 0;
  }
`

const DateEvent = styled(Text.H2)`
  color: white;
`

const Price = styled(Text.H2)`
  color: white;
`

const FrameSpinner = styled(Spinner)`
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform:translate(-50%,-50%);
`

const FrameWrapper = styled.div`
  position: relative;
`

const Frame = styled.iframe`
  display: block;
  margin: 0;
  box-shadow: ${theme.boxShadowSelected};
  border-radius: ${theme.borderRadius};
  width: calc(100vw - 56vh);
  height: 95vh;
  border: 0;
  background: url(${p => p.image}) center center no-repeat;
  background-size: cover;
`

const FixedButton = styled(Button)`
  width: 14vw;
`

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.interval()};
`

const DateModal = styled.div`
  text-align: right;
  min-width: 18vw;
  align-self: start;
  margin-left: auto;
`

const ALLOW = 'autoplay *; encrypted-media *; picture-in-picture *; microphone *; camera *;'
const PLACEHOLDER_TEXT = 'Please, load this screen from content list'

const todayIsBefore = () => {
}


function OnUnmountHandler(props) {
  useEffect(() => {
    return () => {
      document.withNavigation.setDisabled(false)
    }
  }, [])
  return null
}

@inject('pageStore', 'contentStore', 'newrowStore', 'sharedStore')
@observer
class Newrow extends Component {

  iframe = createRef()
  @observable hasModal = false

  @observable isPlaying = false
  @observable isRSVPShow = false
  @observable isDetailsExpanded = false

  @observable camera = true
  @observable sound = true
  @observable iframeIsLoading = false

  @observable isReturnLaterModal = false

  focusInterval = null

  @computed get content() {
    const { contentStore, match } = this.props
    return { ...contentStore.contentFor(match.params.contentId) }
  }

  handleStopRead = () => {
    this.props.sharedStore.stopReadText()
  }

  componentDidMount() {
    const { pageStore, contentStore, match } = this.props
    const { content_header } = contentStore.contentFor(match.params.contentId)
    pageStore.setTitle(content_header)
    document.withNavigation.forceRefresh()
  }

  componentWillUnmount() {
    this.handleStopRead()
    if (this.sound) {
      this.postMessageToIframe('newrowTopBarEvents', 'audioToggle')
    }
    if (this.camera) {
      this.postMessageToIframe('newrowTopBarEvents', 'videoToggle')
    }

    this.isRSVPShow = false
    this.isPlaying = false

    const frame = document.getElementById("newrowIframe");
    if (frame) {
      frame.src = frame.src;
      setTimeout(function () {
        frame.parentNode.removeChild(frame);
        frame.remove();
      }, 1000);
    }
  }

  saveContent = () => {
    const { contentStore, content_id } = this.props
    contentStore.saveContent(content_id)
  }

  @action openDetails = () => {
    this.isDetailsExpanded = true
  }
  @action closeDetails = () => {
    this.isDetailsExpanded = false
    this.handleStopRead()
  }

  @action
  play = () => {
    const { newrowStore, match } = this.props
    this.iframeIsLoading = true
    this.handleStopRead()
    jsbridge.call('learn', match.params.contentId)
    newrowStore.getContent(match.params.contentId)

    this.focusInterval = setInterval(() => document.withNavigation.setFocus(), 1000)

    this.isPlaying = true
    this.camera = true
    this.sound = true
    document.withNavigation.setDisabled(true)
    setTimeout(() => {
      this.iframeIsLoading = false
      document.withNavigation.setDisabled(false, true)
    }, 5000)
  }

  @action
  pause = () => {
    const { contentStore, match } = this.props
    jsbridge.call('exitlearn', match.params.contentId)
    this.isPlaying = false
    if (this.sound) {
      this.postMessageToIframe('newrowTopBarEvents', 'audioToggle')
    }
    if (this.camera) {
      this.postMessageToIframe('newrowTopBarEvents', 'videoToggle')
    }
    clearInterval(this.focusInterval)

    const frame = document.getElementById("newrowIframe");
    if (frame) {
      frame.src = frame.src;
      setTimeout(function () {
        frame.parentNode.removeChild(frame);
        frame.remove();
      }, 1000);
    }
  }

  handleClass = () => {
    const { content_start_date } = this.content
    const starts = parseISO(content_start_date)
    const fiveMinutesBefore = subMinutes(new Date(), 5)
    return !content_start_date || isBefore(starts, fiveMinutesBefore) ? this.handleToggle() : this.handleReturnLater()
  }

  handleToggle = () => this.isPlaying ? this.pause() : this.play()

  @action handleReturnLater = () => this.isReturnLaterModal = !this.isReturnLaterModal

  @action handleRSVPToggle = () => this.isRSVPShow = !this.isRSVPShow

  handleReadText = (type) => () => this.props.sharedStore.isNowReading ? this.handleStopRead() : type === 'rsvp' ? this.handleStartReadRsvp() : this.handleStartRead()

  handleStartReadRsvp = () => {
    const { sharedStore } = this.props
    const { content_header, content_rsvp } = this.content
    const text = (content_header ? content_header : "") + '.' + ' ' + content_rsvp
    sharedStore.readText(text)
  }

  handleStartRead = (type) => {
    const { sharedStore } = this.props

    const { content_header, content_address, content_start_date, content_price, content_markup } = this.content

    const desc = content_markup ? content_markup.replace(/<\/?[^>]+(>|$)/g, '') : ''
    const starts = content_start_date ? format(parseISO(content_start_date), config.dateTimeFormat) : ''

    const text = content_header ? content_header + '.' + ' ' + content_address + '.' + ' ' + starts + '.' + ' ' + content_price + '.' + ' ' + desc : PLACEHOLDER_TEXT
    sharedStore.readText(text)
  }

  postMessageToIframe = (type, action) => {
    if (!this.iframe.current) return false
    this.iframe.current.contentWindow?.postMessage(
      {
        type: type,
        action: action
      },
      '*'
    )
  }

  toggleSound = () => {
    this.sound = !this.sound
    this.postMessageToIframe('newrowTopBarEvents', 'audioToggle')
  }

  toggleVideo = () => {
    this.camera = !this.camera
    this.postMessageToIframe('newrowTopBarEvents', 'videoToggle')
  }

  handleFrameOnload = () => {
    setTimeout(() => {
      this.postMessageToIframe('newrowMediaEvents', 'activateMediaDevices')
      if (this.isPlaying) document.withNavigation.setDisabled(false, true)
    }, 4000)
  }

  render() {

    const { newrowStore, sharedStore, pageStore } = this.props
    const { content_thumbnail, content_rsvp, content_header, content_start_date, content_price, content_markup, ...rest } = this.content
    const starts = content_start_date ? format(parseISO(content_start_date), config.dateTimeFormat) : ''
    const text = content_markup?.replace(/(<\/?[^>]+(>|$))|(&nbsp;)|(&amp;)|(null)/g, ' ') || ''

    return (
      <NewrowContainer>
        <Thumb
          src={content_thumbnail || newrowImg}
          header={<DateEvent><Text.H2>Lesson will start at: <br /><b>{`${starts}`}</b></Text.H2></DateEvent>}
          footer={<Price bold>{content_price}</Price>}
        />
        <StyledButtonsRow marginTop>
          <Button text={this.isPlaying ? 'Stop watching' : 'Live Class'} icon='tv' onPress={this.handleClass} first focusOrder={1} />
          <Button text='Details' icon='file-alt' onPress={this.openDetails} />
          <Button text='RSVP' icon='file-alt' onPress={this.handleRSVPToggle} />
        </StyledButtonsRow>
        {this.isDetailsExpanded && (
          <Modal onBack={this.closeDetails}>
            <MessageModal>
              <ModalHeader>
                <ModalTitle bold>{content_header}</ModalTitle>
                <DateModal><Text.H3 thin>{`at ${starts}`}</Text.H3></DateModal>
              </ModalHeader>
              <ScrollableText isNowReading={sharedStore.isNowReading} onReadPress={this.handleReadText('details')} onClosePress={this.closeDetails}>
                <Text.H2 thin>{text}</Text.H2>
              </ScrollableText>
            </MessageModal>
          </Modal>
        )}
        {this.isRSVPShow && (
          <Modal onBack={this.handleRSVPToggle}>
            <MessageModal>
              <ModalTitle bold>{content_header}</ModalTitle>
              <Text.H2 thin>{content_rsvp}</Text.H2>
              <ButtonsModal>
                <FixedButton text={sharedStore.isNowReading ? 'Stop' : 'Read'} icon={sharedStore.isNowReading ? 'volume-off' : 'volume-up'} onPress={this.handleReadText('rsvp')} dark />
                <Button text='Close' inline focusOrder={0} first onPress={this.handleRSVPToggle} dark />
              </ButtonsModal>
            </MessageModal>
          </Modal>
        )}
        {this.isPlaying && (
          <ClassModal onBack={this.pause} color={pageStore.color}>
            <Viewer>
              <OnUnmountHandler />
              <Buttons>
                <StyledButton text={this.sound ? 'Mute' : 'Unmute'} icon={this.sound ? 'volume-mute' : 'volume-up'} onPress={this.toggleSound} />
                <StyledButton text={this.camera ? 'Camera Off' : 'Camera On'} icon={this.camera ? 'video-slash' : 'video'} onPress={this.toggleVideo} />
                <StyledButton text='Stop watching' icon='times' onPress={this.handleToggle} first focusOrder={0.5} />
              </Buttons>
              <FrameWrapper>
                <Frame
                  onLoad={this.handleFrameOnload}
                  id='newrowIframe'
                  name='newrowIframe'
                  allow={ALLOW}
                  image={content_thumbnail || newrowImg}
                  src={this.isPlaying ? newrowStore.url : ''}
                  key={this.isPlaying ? newrowStore.url : 'not_loaded'}
                  ref={this.iframe}
                />
                {this.iframeIsLoading && <FrameSpinner />}
              </FrameWrapper>
            </Viewer>
          </ClassModal>
        )}
        {this.isReturnLaterModal && (
          <Modal onBack={this.handleReturnLater}>
            <MessageModal>
              <ModalTitle>This live session hasn't started yet.</ModalTitle>
              <ModalContent>Class will start at <b>{starts}</b></ModalContent>
              <ButtonsRow>
                {/*<Button text='Add to diary' icon='plus' />*/}
                <Button text='Close' first focusOrder={0} icon='times' onPress={this.handleReturnLater} />
              </ButtonsRow>
            </MessageModal>
          </Modal>
        )}
      </NewrowContainer>
    )
  }
}

export default Newrow
