import React from 'react'
import { render } from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { toJS, isObservable } from 'mobx'
import 'normalize.css'
import 'utils/BI'
import 'utils/dynamicData.ts'

// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React);
// }


// DEV userData
localStorage.setItem('userData', JSON.stringify({"result": "ok", "token": "dF4e9ZMvbPmPNo_xOX5NkIDJLjmngMyXOTY87BaR", "id": 618, "profile": {"last_login": null, "is_superuser": false, "is_staff": false, "date_joined": "2020-06-08T15:44:03.744Z", "user_id": 618, "user_email": "sergey.rubanov+elder@initech.co.il", "salutation": "", "first_name": "sergey", "middle_name": "elder", "last_name": "rubanov", "gender": null, "gender_display": null, "user_mobilephone": null, "coord": null, "user_country": null, "user_region": null, "user_city": "", "user_zip": null, "user_street": null, "user_street_number": null, "user_apartment_number": null, "user_address": null, "birthdate": null, "user_marital_status": null, "marital_status_display": null, "social_match": false, "user_photo": "https://s3-eu-west-1.amazonaws.com/izidoo-dev-photos/VKzvr439o0mLDxjI5P1-", "enabled": true, "fcm_token": null, "created_by_user_id": 421, "created_for_user_id": null, "properties": {}, "groups": [], "user_permissions": [], "user_name": "sergey elder rubanov", "photo": "https://s3-eu-west-1.amazonaws.com/izidoo-dev-photos/VKzvr439o0mLDxjI5P1-", "timezone": "Europe/London", "user_lon": null, "user_lat": null, "disabled": "no", "user_birthday": null, "user_birthmonth": null, "user_birthyear": null, "social_matching": "no", "user_gender": null, "user_gender_display": null}, "apps": [{"id": 106, "user_id": 618, "device_id": "TV1", "auth_server_path": "https://backend.dev.izidoo-cms.org/", "content_server_path": "https://backend.dev.izidoo-cms.org/", "call_server_path": "https://sparko-video.herokuapp.com/comm"}]}))
// PROD userData
// localStorage.setItem('userData', JSON.stringify({"result":"ok","token":"_6nw7cGl0O36LVzUZOlOmJc4dj-mmbVgFixEPHzW","id":651,"profile":{"last_login":null,"is_superuser":false,"is_staff":false,"date_joined":"2020-06-08T08:40:25.438Z","user_id":651,"user_email":"sergey.rubanov+caregiver@initech.co.il","salutation":"","first_name":"sergey","middle_name":"caregiver","last_name":"rubanov","gender":null,"gender_display":null,"user_mobilephone":null,"coord":null,"user_country":null,"user_region":null,"user_city":"","user_zip":null,"user_street":null,"user_street_number":null,"user_apartment_number":null,"user_address":null,"birthdate":null,"user_marital_status":null,"marital_status_display":null,"social_match":false,"user_photo":"https://s3-eu-west-1.amazonaws.com/sparko-prod-users-photos/QUKfBmUzko6EkBEydaa-","enabled":true,"fcm_token":null,"created_by_user_id":473,"created_for_user_id":null,"groups":[],"user_permissions":[],"user_name":"sergey caregiver rubanov","photo":"https://s3-eu-west-1.amazonaws.com/sparko-prod-users-photos/QUKfBmUzko6EkBEydaa-","timezone":"Europe/London","user_lon":null,"user_lat":null,"disabled":"no","user_birthday":null,"user_birthmonth":null,"user_birthyear":null,"social_matching":"no","user_gender":null,"user_gender_display":null},"apps":[{"id":194,"user_id":651,"device_id":"TV1","auth_server_path":"https://backend.sparko.tv/","content_server_path":"https://backend.sparko.tv/","call_server_path":"https://video-call-service.sparko.tv/comm"}],"caregiving_for":[{"id":650,"name":"sergey Elder rubanov","photo":"https://s3-eu-west-1.amazonaws.com/sparko-prod-users-photos/WdnOBEf64Fqps2Xu41_-","timezone":"Europe/London"}]}))

global.log = (...args) => {
  const js = args.map(item => isObservable(item) ? toJS(item) : item)
  console.log('---', ...js)
}

Boolean.prototype.toggle = function () {
  return !this
}

Image.prototype.load = function (url) {
  var thisImg = this
  var xmlHTTP = new XMLHttpRequest()
  xmlHTTP.open('GET', url, true)
  xmlHTTP.responseType = 'arraybuffer'
  xmlHTTP.onload = function (e) {
    var blob = new Blob([this.response])
    thisImg.src = window.URL.createObjectURL(blob)
  }
  xmlHTTP.onprogress = function (e) {
    thisImg.completedPercentage = parseInt((e.loaded / e.total) * 100)
  }
  xmlHTTP.onloadstart = function () {
    thisImg.completedPercentage = 0
  }
  xmlHTTP.send()
}

Image.prototype.completedPercentage = 0

Array.prototype.first = function () {
  return this[0]
}

function getCaller() {
  var stack = getStack()


  // Remove superfluous function calls on stack
  stack.shift() // getCaller --> getStack
  stack.shift() // omfg --> getCaller

  // Return caller's caller
  return stack[1].receiver
}

function getStack() {
  // Save original Error.prepareStackTrace
  var origPrepareStackTrace = Error.prepareStackTrace

  // Override with function that just returns `stack`
  Error.prepareStackTrace = function (_, stack) {
    return stack
  }

  // Create a new `Error`, which automatically gets `stack`
  var err = new Error()

  // Evaluate `err.stack`, which calls our new `Error.prepareStackTrace`
  var stack = err.stack

  // Restore original `Error.prepareStackTrace`
  Error.prepareStackTrace = origPrepareStackTrace

  // Remove superfluous function call on stack
  stack.shift() // getStack --> Error

  return stack
}

Array.prototype.log = function (pick) {
  const name = pick || 'this'
  const toLog = pick ? this.map(item => item[pick]) : this
  console.log(`--- Array -> log -> ${name}`, toLog)
  return this
  // Error.prepareStackTrace = function (_, stack) {
  //   return stack
  // }
  // const error = new Error()
  // const stack = error.stack

}

Object.defineProperty(Array.prototype, 'isEmpty', { get: function () {
    return !this.length
  }
})

// Array.prototype.isEmpty = function() {
//   return !this.length
// }

render(
  <App />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
