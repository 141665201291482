import React, { PureComponent } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { theme } from 'UI'
import Icon from '../Icon/Icon'
import focusable from 'components/withNavigation/focusable'

const pulse = keyframes`
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1.1);
  }
`

const selected = css`
  background: rgba(255,255,255,0.9);
  color: #333333;
  ${p => p.red && `background: ${theme.colors.redRgba(0.9)}; color: white;`};
  transform: scale(1.1);
  box-shadow: ${theme.boxShadowSelected};
  animation: ${p => !p.disabled && pulse} 1.2s ease-in-out 300ms infinite;
  ${p => p.styles?.selected || ''};
`

const notSelected = css`
  background: ${p => p.dark ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0.15)'};
  ${p => p.red && `background: ${theme.colors.redRgba(0.6)}; color: white;`};
  color: white;
  transform: scale(1);
  box-shadow: 0 3px 5px 1px rgba(0,0,0,0.2);
  ${p => p.styles?.notSelected || ''}
`

const ButtonContainer = styled.div`
  display: ${p => p.inline ? 'inline-flex' : 'flex'};
  align-items: center;
  align-content: center;
  ${p => !p.text && 'justify-content: center'};
  height: 10vh;
  min-width: 10vh;
  line-height: 3.8vh;
  font-size: ${theme.fontHeading3};
  border-radius: ${theme.borderRadius};
  transition: all 300ms ease-out;
  font-weight: 700;
  padding: ${theme.interval(0.5, 2)};
  ${p => p.marginLeft && `margin-left: ${theme.interval(2)};`};
  ${p => p.marginRight && `margin-right: ${theme.interval(2)};`};
  ${p => p.selected ? selected : notSelected}
`

const Wrap = styled.div`
  width: ${p => p.isTextEmpty ? 'auto' : theme.interval(5)};
  margin: ${p => p.isTextEmpty ? 0 : theme.interval(0, 1.5, 0, 0)};
  flex: 0 0 ${theme.interval(2)};
`

const IconWrap = styled(Wrap)`
`
const IconWithBadgeWrap = styled(Wrap)`
  margin-right: ${theme.interval(2.5)};
`

const Badge = styled.span`
  font-size: 2em;
  right: -0.35em;
  top: -0.2em;
`
const StyledIcon = styled(Icon)`
  font-size: ${theme.fontHeading2};
  width: ${p => p.isTextEmpty ? 'auto' : `${theme.interval(3)} !important`};
`

const Title = styled.div`
  flex: 1;
`

const IconElement = ({ icon, isTextEmpty }) => <IconWrap isTextEmpty={isTextEmpty}><StyledIcon icon={icon} /></IconWrap>

const IconWithBadge = ({ icon, badge }) => (
  <IconWithBadgeWrap className="fa-layers fa-fw">
    <StyledIcon icon={icon} />
    <Badge className="fa-layers-counter" style={{background:'Tomato'}}>{badge}</Badge>
  </IconWithBadgeWrap>
)

class Button extends PureComponent {

  componentDidMount() {
    const { setCallback, onPress } = this.props
    if (onPress) setCallback(onPress)
  }

  render() {
    const { className, badge, text, icon, iconRight, forwardedRef, selected, children, styles = {}, marginLeft, marginRight, onPress, ...rest } = this.props
    if (children) return <ButtonContainer {...this.props}>{children}</ButtonContainer>
    const upperCaseText = text?.toUpperCase() || text

    // TODO Ugly, need rework DRY
    return (
      <ButtonContainer
        className={['button', className]}
        ref={forwardedRef}
        selected={selected}
        styles={styles}
        marginLeft={marginLeft}
        marginRight={marginRight}
        {...rest}
      >
        {(icon && !badge) && <IconElement icon={icon} isTextEmpty={!text} />}
        {(icon && !!badge) && (<IconWithBadge icon={icon} badge={badge} />)}
        {text && <Title>{upperCaseText}</Title>}
        {(iconRight && !badge) && <IconElement icon={iconRight} isTextEmpty={true} />}
        {(iconRight && !!badge) && (<IconWithBadge icon={iconRight} badge={badge} />)}
      </ButtonContainer>
    )
  }
}

export default focusable(Button)
