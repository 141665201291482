import format from 'date-fns/format'


class BI {

  constructor(options) {
    if (options) this.defaults = { ...this.defaults, options }
  }

  DELAY = 60000
  BI_TITLE = 'sparko_webview_log'
  BASE_URL = 'https://2ad2cb1932d549e7abbdd7734bdd8d19.us-east-1.aws.found.io:9243/'
  disabled = process.env.REACT_APP_DISABLE_BI || false

  queue = []

  logType = 'log'

  defaults = {
    app: 'new-tv',
    app_version: process.env.APP_VERSION,
    env: process.env.NODE_ENV,
    platform: 'webView'
  }

  get send() { return this }

  get error() {
    this.logType = 'error'
    return this
  }

  get log() {
    this.logType = 'log'
    return this
  }

  with = (key, message, params) => {
    const obj = {
      key,
      message,
      params
    }
    this.call(obj)
  }

  call = obj => {
    if (this.disabled && !this.token) return false

    const today = format(new Date, 'yyMMdd')
    const url = `${this.BASE_URL}iz_${today}/${this.BI_TITLE}?pipeline=srv`;

    const data = {
      ...obj,
      ...this.defaults,
      logType: this.logType,
      orig_timestamp: new Date().toISOString()
    }
    const options = {
      method: 'POST',
      mode: 'no-cors',
      headers: new Headers({
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `Basic ${btoa('reporter:reporter')}`
      }),
      body: JSON.stringify(data),
    }

    this.queue.push({ url, options })
    this.tryRequest()
  }

  tryRequest = () => {
    try {
      const tempQueue = [...this.queue]
      let hasError = false
      for (let i = 0; i < tempQueue.length; i++) {
        if (hasError) break
        const { url, options } = tempQueue[i]

        fetch(url, options)
          .then(response => {
            if (!response.ok) throw new Error('BI request error')
            this.queue.shift()
          })
          .catch(error => {
            //setTimeout(() => this.tryRequest(), this.DELAY)/TODO maybe try several times
            hasError = true
          })
      }
    } catch (e) {
      return false
    }
  }
}

const bi = new BI()
export default bi
global.BI = bi
