import { titleCase } from 'change-case'

export function cutTextWhenNeeded(text, maxLength) {
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text
}

export const getNodeTranslateY = node => {
  const string = node.style.transform
  if (!string) return 0
  const regex = /translateY\((calc\()?(.*)px\)+/
  return string.replace(regex, '$2') * 1
}

function debounce(fn, delay) {
  const ms = parseInt(delay, 10)
  let timer
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(() => fn(...args), isNaN(ms) ? 1000 : ms)
  }
}

function throttle(fn, delay) {
  const ms = parseInt(delay, 10)
  let timer = null
  return function (...args) {
    if (!timer) {
      fn(...args)
      timer = setTimeout(() => timer = null, isNaN(ms) ? 1000 : ms)
    }
  }
}

function decorate(fn) {
  function calledWithParam(delay) {
    return (target, property, descriptor) => calledWithoutParam(target, property, descriptor, delay)
  }

  function calledWithoutParam(target, property, descriptor, delay) {
    const method = descriptor.initializer
    descriptor.initializer = function initializer() {
      return fn(method.call(this), delay)
    }
  }

  return function (...arg) {
    if (typeof arg[0] === 'function') return fn(...arg)
    if (typeof arg[0] === 'number' || typeof arg[0] === 'string') return calledWithParam(...arg)
    if (typeof arg[0] === 'object') return calledWithoutParam(...arg)
  }
}

export const debounced = decorate(debounce)
export const throttled = decorate(throttle)
