import React, { Component } from 'react'
import styled from 'styled-components'
import { observable } from 'mobx'
import { observer, inject } from 'mobx-react'
import formatFns from 'date-fns/format'

import config from 'utils/config'
import theme from 'UI/theme'

const StyledClock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;
`

const DatePart = styled.div`
  white-space: nowrap;
`
const TimePart = styled.div`
  white-space: nowrap;
  margin-left: ${theme.interval(1)};
`

@observer
class Clock extends Component {
  timer = null
  @observable time = null
  @observable date = null

  componentDidMount() {
    const {
      time, date,
      format,
      timeFormat,
      dateFormat,
      interval= 1000 || 60000
    } = this.props

    if (!time && !date) this.time = formatFns(new Date(), format || config.dateTimeFormat)
    if (time) this.time = formatFns(new Date(), format || timeFormat || config.timeAMPM)
    if (date) this.date = formatFns(new Date(), format || dateFormat || config.shortDateFormat)

    this.timer = setInterval(() => {
      this.time = formatFns(new Date(), date ? (format || timeFormat || config.timeAMPM) : (format || config.dateTimeFormat))
      this.date = formatFns(new Date(), format || dateFormat || config.shortDateFormat)
    }, interval)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const { date, time, dateStyle, timeStyle, ...rest } = this.props

    return (
      <StyledClock {...rest}>
        {date && <DatePart className={`date`} style={dateStyle}>{this.date}</DatePart>}
        {(!date || time) && <TimePart className={`clock`} style={timeStyle}>{this.time}</TimePart>}
      </StyledClock>
    )
  }
}

export default Clock
